<template>
  <div id="fibras">
    <Header
      imgUrl="header-modulosnutricionales.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_mn')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_Fibras.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 350 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.fibras.descripcion')"
            ></p>
          </div>

          <CaracteristicasNutricionalesImg
            :columnas="1"
            :caracteristicas="caracteristicas"
            img="icono-fibras.png"
            ImgClass="icono-fibras"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableFibras />
    </div>

    <!--  MODO PREPARACION -->
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container txt-justificado">
      <div class="row mt-4">
        <div class="col-md-3 pe-4">
          <p>
            {{ $t("productos.fibras.modoPreparacion") }}
          </p>
        </div>
        <div class="col-md-5 pe-4">
          <p v-html="$t('productos.fibras.modoOrientativo')">
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/images/icons/lavadomanos.png"
            class="img-fluid h60 me-1"
          />
          <p class="align-self-center txt-celeste txt-iconos">
            {{ $t("productos.higiene") }}
          </p>
        </div>
      </div>
      <div class="row mb-4 text-center">
          <span
            ><em>{{ $t("productos.advertenciasTitulo") }}</em>
            {{ $t("productos.advertencias") }}</span
          >
        </div>
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-db-neutro',
        'sustenta-db-vainilla',
        'sustenta-junior-sin-lactosa',
        'cetogenik'
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CaracteristicasNutricionalesImg from "@/components/CaracteristicasNutricionalesImg.vue";
import es from "@/locales/es.json";
// import en from "@/locales/en.json";
import TableFibras from "@/components/tablas/TableFibras.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "Fibras",
  components: {
    Header,
    CaracteristicasNutricionalesImg,
    TableFibras,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: es.productos.fibras.caracteristicas,
        es: es.productos.fibras.caracteristicas,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
