<template>
  <div id="espesanteXanthan">
    <Header
      imgUrl="header-modulosnutricionales.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_mn')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_EspesanteXanthan.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e6e7e8" :texto="$t('productos.saborNeutro')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 250 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.espesanteXanthan.descripcion')"
            ></p>
          </div>

          <CaracteristicasFuncionales
            :columnas="2"
            :caracteristicas="caracteristicas"
            icono="vaso-xanthan.webp"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableEspesanteXanthan />
    </div>

    <!--  MODO PREPARACION -->
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4">
        <div class="col-md-4 pe-4">
          <p>
            {{ $t("productos.espesanteXanthan.modoPreparacion") }}
          </p>
        </div>
        <div class="col-md-4 pe-4">
          <p>
            {{ $t("productos.espesanteXanthan.textoOrientativo") }}
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/images/icons/lavadomanos.png"
            class="img-fluid h60 me-1"
          />
          <p class="align-self-center txt-celeste txt-iconos">
            {{ $t("productos.higiene") }}
          </p>
        </div>
      </div>
      <div class="row mb-4 text-center">
        <span
          ><em>{{ $t("productos.advertenciasTitulo") }}</em>
          {{ $t("productos.advertencias") }}</span
        >
      </div>
    </div>

    <!--  CUADRO ORIENTATIVO DE CONSISTENCIAS -->

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.consistencias.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleCuadroConsistencias = !toggleCuadroConsistencias"
        >
          {{ toggleCuadroConsistencias ? "+" : "-" }}
        </button>
      </div>
    </div>
    <div
      class="container cuadro-consistencias"
      :class="{ oculto: toggleCuadroConsistencias }"
    >
      <table class="table table-responsive">
        <thead>
          <th colspan="2">
            {{ $t("productos.consistencias.textura") }}
          </th>
          <th>
            {{ $t("productos.consistencias.niveles") }}
          </th>
          <th
            v-html="$t('productos.consistencias.bebidas', {ml: 200})"
          ></th>
          <th
            v-html="
              $t('productos.consistencias.suplementos',{ml: 200})
            "
          ></th>
          <th
            v-html="$t('productos.consistencias.alimento',{g: 100})"
          ></th>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                $t(
                  "productos.consistencias.jarabeFluido"
                )
              }}
            </td>
            <td>
              <img
                src="@/assets/images/icons/jarabefluido.png"
                class="img-fluid h40"
              />
            </td>
            <td
              v-html="$t('productos.consistencias.n1')"
            ></td>
            <td class="text-center">
              {{ $t("productos.consistencias.cuchara",{q: "½"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cuchara",{q: "½"}) }}
            </td>
            <td class="text-center">-</td>
          </tr>
          <tr>
            <td>
              {{ $t("productos.consistencias.jarabe") }}
            </td>
            <td>
              <img
                src="@/assets/images/icons/jarabe.png"
                class="img-fluid h40"
              />
            </td>
            <td
              v-html="$t('productos.consistencias.n2')"
            ></td>
            <td class="text-center">
              {{ $t("productos.consistencias.cuchara",{q: "1"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "1 - 2"}) }}
            </td>
            <td class="text-center">-</td>
          </tr>
          <tr>
            <td>
              {{ $t("productos.consistencias.cremosa") }}
            </td>
            <td>
              <img
                src="@/assets/images/icons/cremoso.png"
                class="img-fluid h40"
              />
            </td>
            <td
              v-html="$t('productos.consistencias.n3')"
            ></td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "2"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "2 - 3"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "1"}) }}
            </td>
          </tr>
          <tr>
            <td>
              {{
                $t("productos.consistencias.semiSolida")
              }}
            </td>
            <td>
              <img
                src="@/assets/images/icons/semi-solido.png"
                class="img-fluid h40"
              />
            </td>
            <td
              v-html="$t('productos.consistencias.n4')"
            ></td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "3"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "3 - 4"}) }}
            </td>
            <td class="text-center">
              {{ $t("productos.consistencias.cucharas", {q: "2"}) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row mb-4 escala">
        <img
          :src="getImage('tabla-iddsi.png')"
          class="img-fluid"
        />
      </div>
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'espesante-resist-video',
        'nutricion-clinica-adultos',
        'modulos-nutricionales',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CaracteristicasFuncionales from "@/components/CaracteristicasFuncionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableEspesanteXanthan from "@/components/tablas/TableEspesanteXanthan.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "EspesanteXanthan",
  components: {
    Header,
    Sabor,
    CaracteristicasFuncionales,
    TableEspesanteXanthan,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      toggleCuadroConsistencias: true,
      caracteristicas: {
        en: en.productos.espesanteXanthan.caracteristicas,
        es: es.productos.espesanteXanthan.caracteristicas,
      },
    };
  },
  methods: {
    getImage(fileName) {
      return require("../../assets/images/productos/" +
        this.$i18n.locale +
        "_" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
