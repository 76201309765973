<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.cada100Gpolvo') }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>50</td>
      </tr>
      <tr>
        <td></td>
        <td>Kj</td>
        <td>209</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>g</td>
        <td>12</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>0,10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
      </tr>
      <tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>mg</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>80</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fibra_soluble') }}</td>
        <td>g</td>
        <td>48</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fibra_insoluble') }}</td>
        <td>g</td>
        <td>32</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>83</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableFibras",
};
</script>
